"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-range-slider');
    import('nouislider').then(function (noUiSlider) {
        $slider.each(function () {
            let $this = $(this);
            let sliderElement = $this.find('.js-range-slider__slider')[0];
            let min = $this.data('min-value') || 0;
            let max = $this.data('max-value') || 100;
            let minInput = $this.find('.js-range-slider__min');
            let maxInput = $this.find('.js-range-slider__max');
            let decimalPlaces = 0;
            let steps = $this.data('steps') || 1;

            let startMin = min;
            let startMax = max;

            if (minInput.val()) {
                startMin = minInput.val();
            } else {
                startMin = min;
            }
            if (maxInput.val()) {
                startMax = maxInput.val();
            } else {
                startMax = max;
            }

            if ($this.data('steps') < 1) {
                decimalPlaces = $this.data('steps').toString().length - 2;
            }

            noUiSlider.create(sliderElement, {
                range: {
                    'min': [min],
                    'max': [max]
                },
                start: [startMin, startMax],
                step: steps,
                connect: true
            });

            sliderElement.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];

                if ( handle === 0 ) {
                    $this.find('.js-range-slider__val-min').text((value));
                    minInput.val(value.toFixed(decimalPlaces));
                    $this.find('[data-handle="'+ handle +'"]').attr('data-text', value.toFixed(decimalPlaces));
                } else {
                    $this.find('.js-range-slider__val-max').text((value));
                    maxInput.val(value.toFixed(decimalPlaces));
                    $this.find('[data-handle="'+ handle +'"]').attr('data-text', value.toFixed(decimalPlaces));
                }
            });

            sliderElement.noUiSlider.on('set', function(values, handle) {
                if (handle === 0) {
                    minInput.trigger('change');
                } else {
                    maxInput.trigger('change');
                }
            });

        });
    });
}